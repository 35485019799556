<template>
  <GModal
    :show="showModal"
    ref="modal"
    class="modal-delete"
    @close="denyAndCancel"
    @keyup.esc="denyAndCancel"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <section class="content">
      <div class="icon-container">
        <TrashIcon class="icon" />
      </div>
      <span class="modal-heading">Confirm Deletion</span>
      <hr class="modal-separator" />
      <slot name="message">
        <p>
          Are you sure you want to {{ verb.toLowerCase() }} {{ countText }}? <br />
          This cannot be undone.
        </p>
      </slot>
      <div class="button-container">
        <PassportButton type="button" @click="resolveConfirm(true)">Yes, I want to {{ verb }} this</PassportButton>
        <PassportButton variant="text" type="button" @click="denyAndCancel">Cancel</PassportButton>
      </div>
    </section>
  </GModal>
</template>

<script>
import { GModal } from '@twentyfourg/grimoire';
import PassportButton from '@/components/PassportButton.vue';
import TrashIcon from '@/assets/icons/trash_icon.svg';

export default {
  name: 'ModalDelete',
  components: {
    PassportButton,
    GModal,
    TrashIcon,
  },
  props: {
    icon: {
      type: [Object, Function],
      default: null,
    },
    deleteCount: {
      type: Number,
      default: null,
    },
    verb: {
      type: String,
      default: 'Delete',
    },
    item: {
      type: String,
      default: 'item',
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  // non reactive data
  resolveCallback: null,
  computed: {
    countText() {
      if (!this.deleteCount) return 'this';
      return `(${this.deleteCount}) ${this.item}${this.deleteCount === 1 ? '' : 's'}`;
    },
  },
  watch: {},
  methods: {
    denyAndCancel() {
      this.resolveConfirm(false);
      this.$emit('cancel');
    },
    resolveConfirm(response) {
      this.showModal = false;
      if (this.$options.resolveCallback) {
        this.$options.resolveCallback(response);
      }
    },
    confirm() {
      this.showModal = true;
      return new Promise((resolve) => {
        this.$options.resolveCallback = resolve;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-delete {
  ::v-deep {
    .modal {
      max-width: 450px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
    padding: 90px;
    text-align: center;
    background-image: url(../assets/images/bg_modal.png);
    background-repeat: no-repeat;
    background-size: cover;
    p {
      margin: 0;
    }
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
      margin-bottom: 40px;
      background: $light-opacity;
      border-radius: 50%;
      svg {
        width: 31px;
        height: auto;
        fill: #fff;
      }
    }
  }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
  .modal-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    font-size: 34px;
  }
  .modal-separator {
    width: 200px;
    margin-bottom: 2em;
  }
  ::v-deep .passport-button.default.primary {
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 1em;
    font-size: 14px;
    text-transform: uppercase;
  }
}
</style>
