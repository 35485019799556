<template>
  <div class="base-file-upload">
    <div class="select-file">
      <template v-if="thumbnailPreview || file">
        <img
          v-if="thumbnailPreview"
          width="100px"
          :src="thumbnailPreview"
          :alt="fileName ? fileName : 'File preview.'"
        />
        <span v-else-if="fileName">{{ fileName }}</span>
        <PassportButton variant="text danger" class="remove" @click="removeFile(false)">
          Remove
          <IconX />
        </PassportButton>
      </template>
      <template v-else>
        <p>No file uploaded</p>
        <div class="select-file__button">
          <PassportButton variant="text" @click.self="$refs.file.click()">
            <label :for="`file-button-${uploadId}`">{{ label }} <IconUpload /></label>
          </PassportButton>
          <input
            ref="file"
            type="file"
            :accept="allowedFileTypes"
            :id="`file-button-${uploadId}`"
            @change="chooseFile"
          />
        </div>
      </template>
    </div>
    <ModalDelete ref="deleteModal" :icon="modalIcon" verb="remove" />
    <!-- Cropper Modal -->
    <GModal v-model="isModalOpen" class="modal-crop" type="drawer" drawerDirection="from-right" @closed="cancelCropper">
      <template v-slot:header="{ close }">
        <PassportButton variant="text" v-if="$mq === 'sm'" class="icon" @click.prevent="close">
          <IconX />
        </PassportButton>
      </template>
      <template v-slot>
        <div class="modal-crop-left">
          <img ref="cropperImg" />
        </div>
      </template>
      <template v-slot:footer="{ close }">
        <PassportButton class="button" @click.prevent="saveCropper">Save</PassportButton>
        <PassportButton class="button" variant="secondary" @click.prevent="close">Cancel</PassportButton>
      </template>
    </GModal>
  </div>
</template>

<script>
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import Cropper from 'cropperjs';
import { GModal } from '@twentyfourg/grimoire';
import ModalDelete from '@/components/ModalDelete.vue';
import IconUpload from '@/assets/icons/upload_icon.svg';
import IconTrash from '@/assets/icons/trash_icon.svg';
import IconX from '@/assets/icons/x_icon.svg';
import { ApiService } from '@/services/api.service';
import PassportButton from '@/components/PassportButton.vue';

import 'cropperjs/dist/cropper.css';
import '@uppy/core/dist/style.css';

export default {
  components: {
    IconUpload,
    IconX,
    ModalDelete,
    GModal,
    PassportButton,
  },
  name: 'PassportImageUploader',
  props: {
    id: {
      type: String,
      default: null,
    },
    imageWidth: {
      type: Number,
      required: true,
    },
    imageHeight: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: 'Upload File',
    },
    presignedUrlParams: {
      type: Object,
      default: () => ({
        entity: null,
        item: null,
      }),
    },
    value: {
      type: String,
      default: null,
    },
    allowedFileTypes: {
      type: Array,
      default: null,
    },
    maxFileSize: {
      type: Number,
      default: 0,
    },
    filename: {
      type: String,
      default: '',
    },
    showCropper: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uppy: null,
      cropper: null,
      isModalOpen: false,
      thumbnailPreview: null,
      file: null,
      increment: 0,
      modalIcon: IconTrash,
      fileName: null,
    };
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.thumbnailPreview = newValue;
      } else this.thumbnailPreview = null;
    },
    filename(newValue) {
      this.fileName = newValue;
    },
  },
  computed: {
    uploadId() {
      return this.id || this._uid;
    },
    hasFile() {
      return !!this.file;
    },
  },
  mounted() {
    this.thumbnailPreview = this.value ? this.value : null;
    this.fileName = this.filename;
    this.uppy = new Uppy({
      id: `upload-${this.uploadId}`,
      autoProceed: false,
      allowMultipleUploads: false,
      debug: true,
      restrictions: {
        maxFileSize: this.maxFileSize,
        minFileSize: null,
        maxTotalFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: this.allowedFileTypes,
        locale: {},
        infoTimeout: 5000,
      },
    });

    this.uppy.use(ThumbnailGenerator, {
      id: 'ThumbnailGenerator',
      thumbnailWidth: this.imageWidth,
      thumbnailHeight: this.imageHeight,
      thumbnailType: ['image/png', 'image/jpeg'],
      waitForThumbnailsBeforeUpload: false,
    });

    this.uppy.on('file-added', async (file) => {
      this.file = file;
      this.$emit('fileAdded', { file, filename: file.name });
      this.fileName = file.name;
    });
    this.uppy.on('file-removed', () => {
      this.thumbnailPreview = null;
      this.$emit('fileRemoved');
    });

    this.uppy.on('thumbnail:generated', (file, preview) => {
      if (!this.showCropper) {
        this.thumbnailPreview = preview;
        this.fileName = this.file.name;
        // this.$emit('fileAdded', { blob: this.thumbnailPreview, filename: this.file.name });
        return;
      }
      this.isModalOpen = true;

      this.$nextTick(() => {
        this.$refs.cropperImg.src = window.URL.createObjectURL(file.data);
        this.$refs.cropperImg.alt = file.data.name;

        // Create Cropper.js
        this.$nextTick(() => {
          const maxHeight = 0.6 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
          this.cropper = new Cropper(this.$refs.cropperImg, {
            aspectRatio: this.imageWidth / this.imageHeight,
            viewMode: 2,
            background: false,
            autoCropArea: 1,
            minContainerHeight: Math.min(
              (this.imageHeight * this.$refs.cropperImg.parentNode.offsetWidth) / this.imageWidth,
              maxHeight,
            ),
          });
        }, 2000);
      });
    });
  },
  beforeDestroy() {
    this.uppy.close();
  },
  methods: {
    async removeFile(skipConfirmation) {
      if (!skipConfirmation) {
        if (!(await this.$refs.deleteModal.confirm())) return;
      }
      if (this.$refs.file) {
        this.$refs.file.value = '';
      }
      this.uppy.reset();
      this.fileName = null;
      this.file = null;
      this.thumbnailPreview = null;
    },
    chooseFile(event) {
      // This code will hook up our custom file input button to Uppy
      const files = Array.from(event.target.files);

      files.forEach((file) => {
        try {
          this.uppy.addFile({
            source: 'file input',
            name: file.name,
            type: file.type,
            data: file,
          });
        } catch (err) {
          if (err.isRestriction) {
            // handle restrictions
            console.error('Restriction error:', err);
          } else {
            // handle other errors
            console.error(err);
          }
        }
      });
    },
    async uploadFileToS3(id, assetType) {
      try {
        // Get Presigned S3 Link
        const { data } = await ApiService.post(`/target/${id}/asset`, {
          fileFormat: this.file.extension,
          assetType,
        });
        this.$emit('saveFilePath', {
          assetUrl: data.assetUrl,
          presignedUrl: data.presignedUrl,
        });
        // POST file to S3 bucket
        return await this.postS3(data.presignedUrl);
      } catch (err) {
        console.error(err);
        return Promise.reject(new Error('File failed to upload'));
      }
    },
    postS3(presigned) {
      this.increment += 1;
      this.uppy.use(AwsS3, {
        id: `awsS3-${this.uploadId}-${this.increment}`,
        timeout: 300 * 1000, // 5 minutes
        getUploadParameters() {
          return {
            method: 'PUT',
            url: presigned,
          };
        },
      });
      return this.uppy.upload();
    },
    saveCropper() {
      // Get the canvas with image data from Cropper.js
      let fillColor = '#000';
      const fileExtension = this.file.extension;
      if (fileExtension === 'png') {
        fillColor = 'transparent';
      }

      const canvas = this.cropper.getCroppedCanvas({
        width: this.imageWidth,
        height: this.imageHeight,
        fillColor,
        imageSmoothingEnabled: fillColor === 'transparent',
      });
      if (canvas) {
        let fileType = 'image/jpeg';
        if (fileExtension === 'png') {
          fileType = 'image/png';
        }
        canvas.toBlob(
          (blob) => {
            const createdBlob = window.URL.createObjectURL(blob);
            this.thumbnailPreview = createdBlob;
            this.uppy.setFileState(this.file.id, { data: blob });
          },
          fileType,
          0.95,
        );
      }
      this.isModalOpen = false;
      this.cropper.destroy();
      this.fileName = this.file.name;
      this.$emit('fileAdded', { blob: this.thumbnailPreview, filename: this.file.name });
    },
    cancelCropper() {
      this.removeFile(true);
      this.isModalOpen = false;
      this.cropper.destroy();
      this.cropper = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.base-file-upload {
  width: 100%;
  // margin: 15px 0 10px 0;
  .select-file {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 5px;
    p {
      margin: 0;
      margin-top: 0.3em;
      font-size: 18px;
      line-height: 18px;
      opacity: 0.5;
    }
    .remove {
      display: flex;
      align-items: center;
      padding: 7px 10px;
      margin-left: 10px;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.65;
      svg {
        width: 16px;
        margin-left: 10px;
      }
    }
    &__button {
      .g-button {
        padding: 7px 10px;
      }
      label {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        cursor: pointer;

        svg {
          width: 22px;
          margin-bottom: -5px;
          margin-left: 15px;
          stroke: $primary-text;
        }
      }
      input {
        display: none;
      }
    }
  }
  .modal-crop {
    &.overlay {
      background-color: rgba(#000, 0.5);
    }
    ::v-deep {
      .content {
        display: flex;
        flex-direction: column;
        max-width: 420px;
        height: 100%;
        background-color: $lightest-neutral;
        @include bp-md-tablet {
          width: 420px;
        }

        &-header {
          background-color: $dark-neutral;

          .icon {
            padding: 21px;
            svg {
              display: block;
              width: 18px;
              height: 18px;
            }
          }
        }
        &-body {
          img {
            display: block;
            max-width: 100%;
          }
        }
        &-footer {
          display: flex;
          flex-wrap: wrap-reverse;
          justify-content: flex-end;
          padding: 20px;

          @media (min-height: 1000px) {
            margin-top: initial;
          }

          .button {
            margin-top: 10px;
            margin-right: 15px;
          }
        }
      }
    }
  }
}
</style>
